import React from "react"
import { Row, Col } from "react-bootstrap"

const Services = ({ services }) => {
		const serviceGroups = {}
		const serviceSections = []
		services.map((e) => {
			if (e.serviceSection in serviceGroups) {
				serviceGroups[e.serviceSection].push(e)
			} else {
				serviceGroups[e.serviceSection] = [e]
				serviceSections.push(e.serviceSection)
			}
			return null;
		})

		function getTableRows( rows ) {
			return (
				rows.map((row, key) => 
				<tbody key={key.toString() + '-a'}>
				<tr >
					<td>{row.service}</td>
					<td>{row.price}</td>
				</tr>
				<tr>
					<td colSpan="2">{row.description}</td>
				</tr>
				</tbody>
				)
			)
		}

		const buildServiceTables = serviceSections.map((section, key) => {
			return (
			<Col lg="6" className="pricing" key={ key }>
				<table className="pricing-table">
					<thead>
						<tr>
							<th colSpan="2">{serviceGroups[section][0].serviceSection}</th>
						</tr>
					</thead>
						{ getTableRows(serviceGroups[section]) }
				</table>
			</Col>
		)})


	return (
		<div><h3>Services and Pricing</h3>
			<Row>
				{buildServiceTables}
			</Row>
		</div>
	)
}

export default Services;