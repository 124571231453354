import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TitleBlock from "../components/title-block"
import Sidebar from "../components/sidebar"
import Services from "../components/services"
import SEO from "../components/seo"

export default ({ data }) => {
 const post = data.post
 const services = data.services.frontmatter.services
 const headerImage = data.headerImage.childImageSharp.fluid
 const addPricing = () => {
	 if ( services != null ) {
		return (
			<Services services={services}/>
		 )
	 } else {
		 return null
	 }
 }

  return (
    <Layout pageInfo={{ pageName: post.fields.slug }}>
		<SEO title={post.frontmatter.title} description={post.excerpt} />
      <div>
  		<TitleBlock title={ post.frontmatter.title } headerImage={ headerImage }/>
		<Row className="px-3">
			<Col lg="8">
				<div dangerouslySetInnerHTML={{ __html: post.html }} />
  				<div>{addPricing()}</div>
			</Col>
			<Col lg="4">
				<Sidebar />
			</Col>
		</Row>
	  </div>
    </Layout>
  )
}

export const query = graphql`
	query($slug: String!, $addServices: String, $headerImage: String!) {
		post: markdownRemark(fields: {slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				excerpt
				addServices
			}
			fields {
				slug
			}
		}
		services: markdownRemark(frontmatter: {serviceGroup: {eq: $addServices}}) {
			frontmatter {
				services {
					service
					description
					price
					serviceSection
				}
				serviceGroup
			}
		}
		headerImage: file(relativePath: { eq: $headerImage }) {
			childImageSharp {
				fluid (quality: 90, maxWidth: 1140) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`